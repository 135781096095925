body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.scene {
  position: absolute;
  top: 0;
  width: 1080px;
  height: 1920px;
  background-color: black;
}

.full-screen {
  position: absolute;
  top: 0;
  width: 1080px;
  height: 1920px;
}

.center-abs {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

[data-counter], [data-text-video], [data-can-live-text], [data-bathtubes-text], [data-bathtubes-container], [data-facts-container], [data-back] {
  display: none;
}


