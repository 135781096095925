/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Brandon Grotesque Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Regular Italic'), url('./assets/brandon-font/brandon-grotesque-regular-italic-58a8a456c4724.woff') format('woff');
}


@font-face {
  font-family: 'Brandon Grotesque Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Thin Italic'), url('./assets/brandon-font/brandon-grotesque-thin-italic-58a8a3a8861fe.woff') format('woff');
}


@font-face {
  font-family: 'Brandon Grotesque Light';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Light'), url('./assets/brandon-font/brandon-grotesque-light-58a8a4b38001d.woff') format('woff');
}


@font-face {
  font-family: 'Brandon Grotesque Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Light Italic'), url('./assets/brandon-font/brandon-grotesque-light-italic-58a8a4ccb88be.woff') format('woff');
}


@font-face {
  font-family: 'Brandon Grotesque Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Medium Italic'), url('./assets/brandon-font/brandon-grotesque-medium-italic-58a8a3c40866a.woff') format('woff');
}


@font-face {
  font-family: 'Brandon Grotesque Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Bold Italic'), url('./assets/brandon-font/brandon-grotesque-bold-italic-58a8a48221563.woff') format('woff');
}


@font-face {
  font-family: 'Brandon Grotesque Black';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Black'), url('./assets/brandon-font/brandon-grotesque-black-58a8a3e824392.woff') format('woff');
}

*, p, div, h1, h2, span {
  font-family:'Brandon Grotesque Light';font-weight:normal;
}